import twitterLogo from "@/assets/img/logos/twitter-logo.svg";
import blueskyLogo from "@/assets/img/logos/bluesky-logo.svg";
import type { ProviderType } from "~/types/bsky/providers";

export const useAppMode = () => {
  const route = useRoute();
  const appMode = ref<ProviderType>(getAppMode(route.path));
  const appLogo = computed(() => getAppLogo(appMode.value));

  watch(
    () => route.path,
    (path) => {
      appMode.value = getAppMode(path);
    }
  );

  return {
    appMode,
    appLogo,
    getAppLogo,
  };
};

export const getAppMode = (path: string) => {
  return path.includes("/bsky") || window?.location.href.includes("/bsky")
    ? "bsky"
    : "tweetdeleter";
};

export const getAppLogo = (appMode: ProviderType) => {
  return appMode === "tweetdeleter" ? twitterLogo : blueskyLogo;
};
